import styled from "styled-components";

import bgSrc from "../../assets/home-bg-1440.jpg";
import p1Src from "../../assets/polaroid-mobile-menu-1.png";
import p2Src from "../../assets/polaroid-mobile-menu-2.png";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  background-color: #171717;
  background-image: url(${bgSrc});
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
  background-size: 1440 auto;
  z-index: 9999;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 104px;
  padding: var(--space-1-5, 12px) var(--space-3, 24px);
`;

export const Logo = styled.img`
  width: 80px;
`;

export const MenuBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  padding: var(--space-2, 16px) var(--space-3, 24px) var(--space-5, 40px)
    var(--space-3, 24px);
  background-image: url(${p1Src}), url(${p2Src});
  background-repeat: no-repeat;
  background-size: 150px auto, 140px auto;
  background-position-y: 21px, 300px;
  background-position-x: 0, 0;
  overflow-y: auto;
`;

export const MobileNavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--space-1, 8px);
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 90px;
`;

export const NavLink = styled.a`
  display: block;
  color: var(--color-text-inactive, #c3c3c3);
  font-family: "YWFT Ultramagnetic";
  text-decoration: none;
  text-align: right;
  font-size: 56px;
  line-height: 56px; /* 100% */
  text-transform: uppercase;

  &:hover,
  &.--active {
    color: var(--color-text-primary, #fff);
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-4, 32px);
  align-self: stretch;
  width: 100%;
`;

export const Cta = styled.button`
  display: flex;
  height: var(--size-xl, 48px);
  padding: var(--space-0, 0px) var(--space-3, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--space-1, 8px);
  width: 100%;
  max-width: 280px;
  border-radius: var(--radius-circular, 200px);
  background: var(--color-surface-button-primary, #caa042);
  color: var(--color-text-primary, #fff);
  font-family: "GothamPro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--space-3, 24px);
`;

export const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-md, 32px);
  height: var(--size-md, 32px);
  color: #fff;
`;
