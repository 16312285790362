import React from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "YWFT Ultramagnetic";
  text-transform: uppercase;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0;
  color: #fff;

  @media (max-width: 1023px) {
    margin-top: 8px;
    font-size: 38px;
  }

  @media (min-width: 1024px) {
    font-size: 70px;
  }
`;

const Subtitle = styled.h3`
  order: ${p => p.$isReverse && 2};
  margin: 0;
  color: #c49f3f;

  @media (max-width: 1023px) {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    margin-top: ${p => p.$isReverse && "1px"};
    margin-bottom: ${p => !p.$isReverse && "12px"};
    font-size: 34px;
  }
`;

const SectionTitle = ({
  title = "",
  subtitle = "",
  reverse = false,
  ...props
}) => {
  return (
    <Root {...props}>
      <Subtitle $isReverse={reverse}>{subtitle}</Subtitle>
      <Title>{title}</Title>
    </Root>
  );
};

export default SectionTitle;
