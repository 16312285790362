import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding-top: 24px;
  }

  @media (min-width: 1024px) {
    padding-top: 143px;
  }

  .slick-dots {
    bottom: 18px;
    margin: 0;
  }

  .slick-dots li button::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 20px;
    opacity: 1;
  }

  .slick-dots li.slick-active button::before {
    background-color: #fff;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 0 25px;
  }

  @media (min-width: 1024px) {
    max-width: 1550px;
    padding: 0 60px;
  }
`;

export const CardsWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    max-width: 600px;
    margin-top: 60px;

    & + & {
      margin-top: 0;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;

    & + & {
      margin-top: 70px;
      padding-top: 70px;
      background-image: linear-gradient(
        to right,
        #818386 60%,
        rgba(255, 255, 255, 0) 0%
      );
      background-size: 20px 1px;
      background-repeat: repeat-x;
      background-position-y: 0;
    }
  }
`;

export const GrandSlider = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: calc(100% - 540px);
  }

  @media (min-width: 1480px) {
    width: calc(100% - 609px);
  }

  img {
    height: 100%;
    object-fit: cover;
  }
`;

export const GrandText = styled.div`
  @media (max-width: 1023px) {
    margin-top: 32px;
  }

  @media (min-width: 1024px) {
    max-width: 489px;
  }
`;

// -----------------------------------------

export const CardContainer = styled.div`
  @media (max-width: 1023px) {
    margin-top: 40px;
    padding-top: 42px;
    background-image: linear-gradient(
      to right,
      #818386 60%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 20px 1px;
    background-repeat: repeat-x;
    background-position-y: 0;

    & + & {
      margin-top: 48px;
    }
  }

  @media (min-width: 1024px) {
    width: calc(50% - 12px);
  }

  @media (min-width: 1280px) {
    width: calc(50% - 20px);
  }

  @media (min-width: 1600px) {
    width: calc(50% - 35px);
  }
`;

export const Badge = styled.img`
  display: block;
  width: auto;

  @media (max-width: 1023px) {
    position: absolute;
    top: -21px;
    right: -15px;
    height: ${p => (p.$isGrand ? "57px" : "43px")};
  }

  @media (min-width: 1024px) {
    position: ${p => (p.$isGrand ? "relative" : "absolute")};
    left: ${p => !p.$isGrand && "-5px"};
    bottom: ${p => !p.$isGrand && "-36px"};
    height: ${p => (p.$isGrand ? "83px" : "75px")};
  }
`;

export const CardTitle = styled.h5`
  margin-bottom: 0;
  color: #fff;
  font-weight: 900;
  font-family: "YWFT Ultramagnetic";
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0;
    font-size: 24px;
    line-height: 1.33;

    span {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 29px;
    font-size: 34px;
    line-height: 1.29;
  }
`;

export const CardDesc = styled.p`
  @media (max-width: 1023px) {
    color: #fff;
    font-size: 14px;
    line-height: 1.71;
  }

  @media (min-width: 1024px) {
    color: #c3c3c3;
    font-size: 16px;
    line-height: 1.75;
  }
`;

export const List = styled.ul`
  list-style: none;

  @media (max-width: 1023px) {
    margin-top: 24px;
  }

  @media (min-width: 1024px) {
    margin-top: 24px;
  }
`;

export const ListItem = styled.li`
  position: relative;

  @media (max-width: 1023px) {
    padding-left: 25px;
    color: #fff;
    font-size: 14px;
    line-height: 1.43;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  @media (min-width: 1024px) {
    padding-left: 32px;
    color: #c3c3c3;
    font-size: 16px;
    line-height: 1.38;

    &:not(:last-of-type) {
      margin-bottom: 14px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 19' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bb8b2b' d='M 11.086 13.344 L 17.628 16.034 L 13.918 10.011 L 18.5 4.621 L 11.624 6.289 L 7.914 0.267 L 7.375 7.32 L 0.5 8.988 L 7.043 11.68 L 6.505 18.733 L 11.086 13.344 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1279px) {
      top: 3px;
      width: 13px;
      height: 13px;
    }

    @media (min-width: 1280px) {
      top: 0;
      width: 19px;
      height: 19px;
    }

    @media (min-width: 1600px) {
      top: 0;
    }
  }
`;

export const SecondSlider = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    margin-bottom: 33px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 75px;
  }
`;
