import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: var(--space-1-5, 12px) var(--space-3, 24px);
  }

  @media (min-width: 1024px) {
    padding: var(--space-3, 24px) var(--space-10, 80px);

    &.--sticky {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #171717;
      z-index: 10;
    }

    &.--sticky img {
      width: 64px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1023px) {
    align-items: center;
  }

  @media (min-width: 1024px) {
    align-items: flex-start;
    max-width: 1280px;
  }
`;

export const Logo = styled.img`
  @media (max-width: 1023px) {
    width: 80px;
  }

  @media (min-width: 1024px) {
    width: 96px;
  }
`;

export const MenuBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const DesktopNavList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 40px;
  height: 62px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const NavLink = styled.a`
  display: block;
  color: #c3c3c3;
  font-family: "YWFT Ultramagnetic";
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  transition: all 150ms ease-in;

  &:hover {
    border-bottom: 4px solid #caa042;
  }

  &.--active {
    color: #fff;
    border-bottom: 4px solid #caa042;
  }
`;
