import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Root,
  Input,
  CtaError,
  Submit,
  SubmitIcon,
  InputContainer,
} from "./EmailCTA.style";

import ModalEntry from "../../../ModalEntry/ModalEntry";
import ModalRules from "../../../ModalRules/ModalRules";

export const EmailCTA = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [entryShow, setEntryShow] = useState(false);
  const [rulesShow, setRulesShow] = useState(false);
  const [formMessage, setFormMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const handleSubscribe = async () => {
    if (!executeRecaptcha) {
      console.log("Invalid reCAPTCHA. Please try again.");
      setFormMessage({
        type: "error",
        message: "Invalid reCAPTCHA. Please refresh page and try again.",
      });
      return;
    }
    setIsSubmitting(true);
    const captcha = await executeRecaptcha("entry");
    const values = getValues();
    setFormMessage(null);
    fetch("https://api.olesmoky.com/subscribe", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      mode: "cors",
      body: JSON.stringify({
        emailAddress: values.email,
        captchaToken: captcha,
      }),
    })
      .then(response => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(() => {
        setFormMessage({
          type: "success",
          message: "You successfully subscribed for updates!",
        });
        reset();
      })
      .catch(err => {
        console.error(err);
        setFormMessage({
          type: "error",
          message: "An error occurred.  Please try again.",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Root
      onSubmit={handleSubmit(() => setEntryShow(true))}
      // onSubmit={e => {
      //   e.preventDefault();
      //   return handleSubscribe();
      // }}
    >
      <InputContainer>
        <Input
          {...register("email", {
            required: "Please enter your email address",
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
              message: "Invalid email address",
            },
          })}
          name="email"
          type="email"
          autoComplete="off"
          placeholder="YOUR EMAIL ADDRESS"
          // readOnly
        />
        {isSubmitting && (
          <CtaError style={{ color: "#bb8b2b" }}>Submitting...</CtaError>
        )}
        {formMessage && (
          <CtaError style={{ color: "#4BB543" }}>
            {formMessage.message}
          </CtaError>
        )}
        {/* <CtaError>Program has ended.</CtaError> */}
        {/* {errors.email?.message && <CtaError>{errors.email?.message}</CtaError>} */}
      </InputContainer>
      <Submit type="submit">
        <span>enter to win</span>
        <SubmitIcon />
      </Submit>

      {entryShow && (
        <ModalEntry
          initialEmail={getValues("email")}
          onClose={() => setEntryShow(false)}
          onRulesShow={() => {
            setEntryShow(false);
            setRulesShow(true);
          }}
        />
      )}

      {rulesShow && (
        <ModalRules
          onClose={() => {
            setRulesShow(false);
            setEntryShow(true);
          }}
        />
      )}
    </Root>
  );
};
