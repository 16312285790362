import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (min-width: 1024px) {
    padding: 170px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 409px) {
    .slick-prev {
      top: 168px;
      left: -20px;
    }
    .slick-next {
      top: 168px;
      right: -20px;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 32px;
    padding: 0 25px;
  }

  @media (min-width: 410px) {
    .slick-prev,
    .slick-next {
      top: 122px;
    }
  }

  @media (min-width: 1024px) {
    max-width: 1550px;
    margin-top: 63px;
    padding: 0 60px;

    .slick-prev,
    .slick-next {
      top: 50%;
    }
  }
`;

export const Image = styled.img`
  position: absolute;
  max-width: 382px;
  pointer-events: none;
  transition: all 300ms ease;

  @media (max-width: 1023px) {
    bottom: 0;
  }

  @media (min-width: 1024px) {
    top: 0;
    width: 85%;
  }
`;

export const SlideBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 426px;
  width: 100%;

  @media (min-width: 1024px) {
    padding-bottom: 52px;
    background-color: #202020;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 11px;
      background-color: #202020;
      transition: all 300ms ease;
    }
  }
`;

export const Slide = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  cursor: pointer;

  @media (min-width: 1024px) {
    width: 100%;
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;

    &:hover ${Image} {
      transform: translateY(-10px);
    }

    &:hover ${SlideBody}::before {
      transform: translateY(-10px);
    }
  }

  @media (min-width: 1280px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const Title = styled.div`
  font-family: "YWFT Ultramagnetic";
  font-size: 20px;
  text-transform: uppercase;
`;

export const Action = styled.button`
  display: flex;
  align-items: center;
  color: #c49f3f;
  font-weight: 700;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='%23c49f3f' d='M7.196 5.996 3.208 2.011a.75.75 0 0 1 0-1.064.76.76 0 0 1 1.067 0l4.519 4.516a.752.752 0 0 1 .022 1.039l-4.538 4.547a.753.753 0 1 1-1.067-1.064l3.985-3.989Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-y: center;
  transition: all 300ms ease;
  cursor: pointer;

  @media (max-width: 1023px) {
    width: 112px;
    height: 24px;
    padding: 2px 0 0;
    background-position-x: calc(100% - 8px);
    background-size: 11px;
    font-size: 12px;
    letter-spacing: 1.2px;
  }

  @media (min-width: 1024px) {
    width: 134px;
    height: 32px;
    padding: 2px 0 0;
    background-position-x: calc(100% - 8px);
    background-size: 12px;
    font-size: 14px;
    letter-spacing: 1.4px;
  }

  &:hover {
    background-position-x: 100%;
  }
`;

export const MentionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  max-width: 800px;
  padding-top: 120px;
`;

export const MentionItem = styled.p`
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 325;
  line-height: 28px; /* 175% */

  a,
  button {
    color: var(--color-text-highlight, #caa042);
    font: inherit;
    text-decoration: none;
  }
`;
