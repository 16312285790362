import styled from "styled-components";

export const Root = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding-top: 5px;
  color: #2b2e34;
  background-color: #d6c8ac;
  font-family: "YWFT Ultramagnetic";
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  svg {
    margin: 0 8px;
    transform: translateY(-3px);
  }
`;
