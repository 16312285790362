import React, { useEffect, useRef } from "react";
import { Portal } from "react-portal";
import Slider from "react-slick";

import {
  Root,
  Dialog,
  Content,
  Close,
  Body,
  Slide,
  TitleContainer,
  Image,
  RecipeContainer,
  Title,
  RecipeBody,
  RecipeBlock,
  RecipeTitle,
  RecipeList,
  RecipeListItem,
  DirIndex,
  Subtitle,
} from "./RecipesModal.style";

import useLockBodyScroll from "../../../../hooks/useLockBodyScroll";
import SliderStyled from "../../../SliderStyled/SliderStyled";

import { slides } from "./config";

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const RecipesModal = ({
  initialSlide = 0,
  onClose,
  closeOnClickBackground = true,
  closeOnEsc,
}) => {
  const rootEl = useRef(null);
  useLockBodyScroll();

  const handleMouseDismiss = e => {
    if (e.target === rootEl.current) onClose();
  };

  const handleEscDismiss = event => {
    if (event.keyCode === 27 && onClose) onClose();
  };

  useEffect(() => {
    if (!closeOnEsc) return undefined;
    window.addEventListener("keydown", handleEscDismiss);

    return () => {
      window.removeEventListener("keydown", handleEscDismiss);
    };
  });

  console.log({ initialSlide });

  return (
    <Portal>
      <Root
        tabIndex={-1}
        role="dialog"
        aria-modal
        ref={rootEl}
        onClick={onClose && closeOnClickBackground ? handleMouseDismiss : null}
      >
        <Dialog>
          <Content>
            {onClose && (
              <Close type="button" onClick={onClose}>
                <svg
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 26 26"
                >
                  <path
                    fill="currentColor"
                    d="M23.888 26 13 15.112 2.112 26 .001 23.889l10.888-10.888L0 2.112 2.112 0l10.889 10.889L23.889.001 26 2.112 15.112 13 26 23.888 23.888 26Z"
                  />
                </svg>
              </Close>
            )}

            <Body>
              <SliderStyled>
                <Slider {...sliderSettings} initialSlide={initialSlide}>
                  {slides.map(slide => (
                    <Slide key={slide.id}>
                      <Image
                        src={slide.imageSrc}
                        alt={`Image of the ‘${slide.label}’ cocktail`}
                      />
                      <RecipeContainer>
                        <TitleContainer>
                          <Subtitle>Shine Responsibly®</Subtitle>
                          <Title>{slide.label}</Title>
                        </TitleContainer>

                        <RecipeBody>
                          <RecipeBlock>
                            <RecipeTitle>Ingredients</RecipeTitle>
                            <RecipeList>
                              {slide.ingredients.map(item => (
                                <RecipeListItem key={item.id}>
                                  {item.text}
                                </RecipeListItem>
                              ))}
                            </RecipeList>
                          </RecipeBlock>
                          <RecipeBlock>
                            <RecipeTitle>Garnish</RecipeTitle>
                            <RecipeList>
                              {slide.garnish.map(item => (
                                <RecipeListItem key={item.id}>
                                  {item.text}
                                </RecipeListItem>
                              ))}
                            </RecipeList>
                          </RecipeBlock>
                        </RecipeBody>
                      </RecipeContainer>
                    </Slide>
                  ))}
                </Slider>
              </SliderStyled>
            </Body>
          </Content>
        </Dialog>
      </Root>
    </Portal>
  );
};

export default RecipesModal;
