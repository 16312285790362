import { useState, useEffect, useRef } from 'react';

export default function useIntersection(ref, threshold = 0.8) {
  const [isIntersected, setIsIntersected] = useState(false);
  const previousIntersectionRatio = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      const ob = new IntersectionObserver(
        ([entry], observer) => {
          if (entry.intersectionRatio >= threshold) {
            setIsIntersected(true);
          } else if (
            previousIntersectionRatio.current != null &&
            previousIntersectionRatio.current < entry.intersectionRatio &&
            entry.rootBounds.height < entry.boundingClientRect.height
          ) {
            setIsIntersected(true);
          } else {
            setIsIntersected(false);
          }
          previousIntersectionRatio.current = entry.intersectionRatio;
        },
        { threshold: [threshold / 3, threshold / 2, threshold] },
      );
      ob.observe(currentRef);

      return () => {
        ob.unobserve(currentRef);
      };
    }

    return () => {};
  }, [ref, threshold]);
  return isIntersected;
}
