import React, { useRef, useEffect } from "react";
import Slider from "react-slick";

import {
  Root,
  Container,
  CardsWrapper,
  GrandSlider,
  CardContainer,
  GrandText,
  Badge,
  CardTitle,
  CardDesc,
  List,
  ListItem,
  SecondSlider,
} from "./Prizes.style";

import grandPrizeSrc from "../../../../images/grand-prize.svg";
import firstPrizeSrc from "../../../../images/first-prize.svg";
import secondPrizeSrc from "../../../../images/second-prize.svg";

import useIntersection from "../../../../hooks/useIntersection";
import { grandPrize, firstPrize, secondPrize, sliderSettings } from "./config";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import SliderStyled from "../../../SliderStyled/SliderStyled";

export const Prizes = ({ id, onIntersected }) => {
  const ref = useRef(null);
  const isIntersected = useIntersection(ref);

  useEffect(() => {
    if (isIntersected) {
      onIntersected();
    }
  }, [isIntersected, onIntersected]);

  return (
    <Root ref={ref} id={id}>
      <Container>
        <SectionTitle title="sweepstakes" subtitle="WIN A PRIZE" />

        <CardsWrapper>
          <GrandSlider>
            <SliderStyled>
              <Slider {...sliderSettings}>
                {grandPrize.map((slide, idx) =>
                  slide.href == null ? (
                    <img key={idx} src={slide.image} alt="" />
                  ) : (
                    <a key={idx} href={slide.href} target="_blank">
                      <img src={slide.image} alt="" />
                    </a>
                  ),
                )}
              </Slider>
            </SliderStyled>
          </GrandSlider>

          <GrandText>
            <Badge src={grandPrizeSrc} alt="Grand Prize" $isGrand />
            <CardTitle>A VIP TRIP TO THE SMOKY MOUNTAINS (1 WINNER)</CardTitle>
            <CardDesc>
              Ole Smoky is sending moonshine fans to Gatlinburg, Tennessee for a
              Smoky Mountain weekend experience filled with food, fun, and, of
              course, a little 'shine!
            </CardDesc>

            <List>
              <ListItem>Roundtrip Airfare (4 ppl)</ListItem>
              <ListItem>Hotel in Gatlinburg, TN for 3 nights (4 ppl)</ListItem>
              <ListItem>VIP transport to & from airport</ListItem>
              <ListItem>
                Private Moonshine Tour @ The Holler Distillery
              </ListItem>
              <ListItem>Gatlinburg Attraction Pass (4 ppl)</ListItem>
              <ListItem>Ole Smoky Gift Basket</ListItem>
              <ListItem>$500 Gift Card for Meals</ListItem>
            </List>
          </GrandText>
        </CardsWrapper>

        <CardsWrapper>
          <CardContainer>
            <SecondSlider>
              <SliderStyled>
                <Slider {...sliderSettings} autoplaySpeed={4000}>
                  {firstPrize.map(slide => (
                    <img key={slide.id} src={slide.image} alt="" />
                  ))}
                </Slider>
              </SliderStyled>
              <Badge src={firstPrizeSrc} alt="First Prize" />
            </SecondSlider>

            <CardTitle>Ole Smoky Hammock (3 WINNERS)</CardTitle>
            <CardDesc>
              Ole Smoky is awarding three lucky winners a quality, Ole Smoky
              branded hammock. Kick back & enjoy!
            </CardDesc>
          </CardContainer>

          <CardContainer>
            <SecondSlider>
              <SliderStyled>
                <Slider {...sliderSettings} autoplaySpeed={4500}>
                  {secondPrize.map(slide => (
                    <img key={slide.id} src={slide.image} alt="" />
                  ))}
                </Slider>
              </SliderStyled>
              <Badge src={secondPrizeSrc} alt="Second Prize" />
            </SecondSlider>

            <CardTitle>Ole Smoky T-shirt (100 WINNERS)</CardTitle>
            <CardDesc>
              Ole Smoky is awarding 100 lucky winners with an exclusive t-shirt
              as a token of appreciation! Don’t miss your chance to get one.
            </CardDesc>
          </CardContainer>
        </CardsWrapper>
      </Container>
    </Root>
  );
};
