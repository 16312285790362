"use client";
import React from "react";
import { DateTime } from "luxon";

import { Root } from "./Counter.style";

import Countdown from "../../../Countdown/Countdown";

export const Counter = () => {
  return (
    <Root>
      <svg width={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 13.848 17.858 L 8.573 13.421 L 2.74 17.017 L 5.322 10.637 L 0.093 6.2 L 6.953 6.712 L 9.575 0.333 L 11.196 7.043 L 18.056 7.554 L 12.23 11.15 L 13.848 17.858 Z"
          fill="#c49f3f"
        ></path>
      </svg>
      <span>Starts In&nbsp;</span>
      <Countdown
        variant="secondary"
        showDecor={false}
        shortNames
        expires={
          DateTime.now() <
          DateTime.fromObject(
            {
              year: 2024,
              month: 4,
              day: 1,
              hour: 0,
              minute: 0,
              second: 0,
            },
            { zone: "America/New_York" },
          )
            ? {
                year: 2024,
                month: 4,
                day: 1,
                hour: 0,
                minute: 0,
                second: 0,
              }
            : {
                year: 2024,
                month: 6,
                day: 30,
                hour: 23,
                minute: 59,
                second: 59,
              }
        }
        timezone="America/New_York"
        style={{ width: "fit-content", padding: 0 }}
      />
      <svg width={14} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 13.848 17.858 L 8.573 13.421 L 2.74 17.017 L 5.322 10.637 L 0.093 6.2 L 6.953 6.712 L 9.575 0.333 L 11.196 7.043 L 18.056 7.554 L 12.23 11.15 L 13.848 17.858 Z"
          fill="#c49f3f"
        ></path>
      </svg>
    </Root>
  );
};
