import React from 'react';

import { Root, Container, PanelText } from './Rules.style';

import OfficialUs from './us';

const Rules = () => {
  return (
    <Root>
      <Container>
        <PanelText>
          <OfficialUs />
        </PanelText>
      </Container>
    </Root>
  );
};

export default Rules;
