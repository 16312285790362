import React from "react";
import PropTypes from "prop-types";

import Modal from "../../../Modal/Modal";
import Rules from "../Rules/Rules";

const ModalRules = ({ onClose }) => {
  return (
    <Modal
      onClose={onClose}
      // closeOnClickBackground={false}
      closeOnEsc
      // closeOutside
      closeOffset="16px"
      closeIconSize="24px"
      customClose={
        <svg
          viewBox="0 0 195 195"
          width="1em"
          height="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeWidth="15"
            d="M8 186 186 8M8 8l179 179"
            stroke="#6D5932"
            fill="none"
          />
        </svg>
      }
      maxWidth="800px"
    >
      <Rules />
    </Modal>
  );
};

ModalRules.propTypes = {
  onClose: PropTypes.func,
};

ModalRules.defaultProps = {
  onClose: null,
};

export default ModalRules;
