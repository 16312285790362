import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
`;

export const PanelText = styled.div`
  p,
  ol,
  ul,
  li,
  a {
    font-size: inherit;
    line-height: 1.2;
  }

  p + p,
  p + ul,
  p + ol,
  ul + p {
    margin-top: 12px;
  }

  ol {
    margin-top: 16px;

    @media (max-width: 1023px) {
      padding-left: 25px;
    }

    @media (min-width: 1024px) {
      padding-left: 35px;
    }

    li + li {
      margin-top: 12px;
    }
  }

  ol ul {
    padding-left: 24px;
  }

  ol ul li {
    margin: 0;
  }

  h3 {
    margin: 32px 0;
    text-align: center;
  }

  a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  u {
    text-decoration: underline;
  }

  .--tableContainer {
    overflow-x: auto;

    @media (max-width: 1023px) {
      margin-left: -16px;
    }

    @media (min-width: 1024px) {
      margin-left: -24px;
    }
  }

  table {
    border: 1px solid black;
    border-collapse: collapse;

    th,
    td {
      padding: 4px 8px;
      border: 1px solid #e9e9e9;
    }

    th {
      text-align: center;
    }
  }

  hr {
    border-top: 1px solid #e9e9e9;
  }
`;
