import React from "react";

import {
  Root,
  Header,
  Logo,
  MenuBtn,
  ContentContainer,
  MobileNavList,
  NavLink,
  SocialContainer,
  Cta,
  Footer,
  SocialLink,
} from "./MobileMenu.style";

import logoSrc from "../../assets/logo.png";

import useLockBodyScroll from "../../../../hooks/useLockBodyScroll";
import { Counter } from "../Counter/Counter";

export const MobileMenu = ({ activeSection, onCloseMenu, onGetNotified }) => {
  useLockBodyScroll();

  return (
    <Root>
      <Counter />

      <Header>
        <a href="https://olesmoky.com/">
          <Logo src={logoSrc} alt="" />
        </a>
        <MenuBtn onClick={onCloseMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48px"
            height="48px"
            viewBox="0 0 48 48"
          >
            <path
              fill="#fff"
              d="M38.561 36.439a1.5 1.5 0 0 1-2.122 2.122L24 26.121 11.561 38.56A1.501 1.501 0 0 1 9.44 36.44L21.879 24 9.44 11.561A1.501 1.501 0 0 1 11.56 9.44l12.44 12.44L36.438 9.44a1.5 1.5 0 1 1 2.122 2.122l-12.44 12.44 12.44 12.438Z"
            />
          </svg>
        </MenuBtn>
      </Header>

      <ContentContainer>
        <MobileNavList>
          <li>
            <NavLink
              to="/#home"
              className={activeSection === "home" && "--active"}
              onClick={onCloseMenu}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              href="/#prizes"
              className={activeSection === "prizes" && "--active"}
              onClick={onCloseMenu}
            >
              Prizes
            </NavLink>
          </li>
          <li>
            <NavLink
              href="/#recipes"
              className={activeSection === "recipes" && "--active"}
              onClick={onCloseMenu}
            >
              Cocktails
            </NavLink>
          </li>
        </MobileNavList>

        <Footer>
          <Cta onClick={onGetNotified}>Enter To Win</Cta>
          <SocialContainer>
            <SocialLink
              target="_blank"
              href="https://www.instagram.com/olesmoky/?hl=en"
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M19.984 12c0-1.417.118-4.458-.391-5.74a3.284 3.284 0 0 0-1.851-1.851c-1.277-.504-4.323-.391-5.74-.391-1.416 0-4.458-.117-5.74.391A3.284 3.284 0 0 0 4.411 6.26C3.907 7.537 4.02 10.583 4.02 12c0 1.417-.117 4.458.391 5.74a3.284 3.284 0 0 0 1.851 1.851c1.278.504 4.324.391 5.74.391 1.417 0 4.458.117 5.74-.391a3.284 3.284 0 0 0 1.851-1.851c.509-1.277.391-4.323.391-5.74Zm1.686 4.015c-.078 1.56-.434 2.942-1.573 4.08-1.138 1.143-2.52 1.499-4.08 1.573-1.608.091-6.426.091-8.034 0-1.56-.078-2.938-.434-4.08-1.573-1.143-1.138-1.499-2.52-1.573-4.08-.092-1.608-.092-6.427 0-8.034.078-1.56.43-2.942 1.573-4.08 1.142-1.139 2.524-1.495 4.08-1.569 1.608-.091 6.426-.091 8.034 0 1.56.078 2.942.434 4.08 1.573 1.143 1.138 1.499 2.52 1.573 4.08.092 1.608.092 6.422 0 8.03Zm-3.306-9.212c0 .648-.522 1.165-1.165 1.165a1.164 1.164 0 1 1 1.165-1.165ZM15.248 12a3.25 3.25 0 0 0-3.246-3.246A3.25 3.25 0 0 0 8.756 12c0 1.79 1.46 3.246 3.246 3.246A3.252 3.252 0 0 0 15.248 12Zm1.747 0a4.985 4.985 0 0 1-4.993 4.993A4.985 4.985 0 0 1 7.009 12a4.985 4.985 0 0 1 4.993-4.993A4.985 4.985 0 0 1 16.995 12Z"
                />
              </svg>
            </SocialLink>
            <SocialLink
              target="_blank"
              href="https://www.facebook.com/olesmoky"
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M18.559 3.653H5.441c-.988 0-1.789.801-1.789 1.789V18.56c0 .987.801 1.788 1.789 1.788h5.115v-5.676H8.208v-2.671h2.348V9.964c0-2.316 1.379-3.595 3.491-3.595 1.011 0 2.069.18 2.069.18v2.274h-1.165c-1.149 0-1.507.712-1.507 1.443v1.735h2.563l-.41 2.671h-2.153v5.676h5.115c.988 0 1.789-.801 1.789-1.788V5.442c0-.988-.801-1.789-1.789-1.789Z"
                />
              </svg>
            </SocialLink>
            <SocialLink
              target="_blank"
              href="https://www.youtube.com/channel/UCHiz5-YT3yX0VqkfGKKy3zw"
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M10.044 15.911V8.06l5.793 3.923-5.793 3.928Zm12.05-8.195c0-1.797-1.323-3.244-2.957-3.244-2.213-.104-4.47-.143-6.776-.143h-.719c-2.301 0-4.562.039-6.776.143-1.63 0-2.952 1.455-2.952 3.252a58.489 58.489 0 0 0-.14 4.267c-.004 1.423.04 2.845.136 4.271 0 1.798 1.322 3.256 2.952 3.256 2.325.108 4.711.156 7.136.152 2.429.008 4.806-.04 7.135-.152 1.634 0 2.957-1.458 2.957-3.256a61.01 61.01 0 0 0 .136-4.275 58.656 58.656 0 0 0-.132-4.271Z"
                />
              </svg>
            </SocialLink>
          </SocialContainer>
        </Footer>
      </ContentContainer>
    </Root>
  );
};
