import slide1Src from "../../assets/coctail-01.png";
import slide2Src from "../../assets/coctail-02.png";
import slide3Src from "../../assets/coctail-03.png";
import slide4Src from "../../assets/coctail-04.png";

export const slides = [
  {
    id: 1,
    label: "Lightnin' Lemonade",
    imageSrc: slide1Src,
    ingredients: [
      {
        id: 1,
        text: "2 oz Ole Smoky White Lightnin' Moonshine",
      },
      {
        id: 2,
        text: "3 oz. Lemonade",
      },
    ],
    garnish: [
      {
        id: 1,
        text: "Fresh lemon wedge (optional)",
      },
    ],
  },
  {
    id: 2,
    label: "Strawberry Lemonade",
    imageSrc: slide2Src,
    ingredients: [
      {
        id: 1,
        text: "2 oz Ole Smoky Strawberry Moonshine",
      },
      {
        id: 2,
        text: "3 oz. Lemonade",
      },
    ],
    garnish: [
      {
        id: 1,
        text: "Fresh lemon wedge (optional)",
      },
    ],
  },
  {
    id: 3,
    label: "Blackberry Lemonade",
    imageSrc: slide3Src,
    ingredients: [
      {
        id: 1,
        text: "2 oz Ole Smoky Strawberry Moonshine",
      },
      {
        id: 2,
        text: "3 oz Lemonade",
      },
    ],
    garnish: [
      {
        id: 1,
        text: "Fresh lemon wedge (optional)",
      },
    ],
  },
  {
    id: 4,
    label: "Hunch Punch Lemonade",
    imageSrc: slide4Src,
    ingredients: [
      {
        id: 1,
        text: "2 oz Ole Smoky Hunch Punch Moonshine",
      },
      {
        id: 2,
        text: "3 oz Lemonade",
      },
    ],
    garnish: [
      {
        id: 1,
        text: "Fresh lemon wedge (optional)",
      },
    ],
  },
];

export const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
