import React, { useState, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import styled from "styled-components";

import GlobalStyle from "../components/GlobalStyle";

import bgSrc from "../components/NationalMoonshineWeek/assets/home-bg-1440.jpg";

import { Counter } from "../components/NationalMoonshineWeek/components/Counter/Counter";
import { Navigation } from "../components/NationalMoonshineWeek/components/Navigation/Navigation";
import { Home } from "../components/NationalMoonshineWeek/sections/Home/Home";
import { Prizes } from "../components/NationalMoonshineWeek/sections/Prizes/Prizes";
import { Recipes } from "../components/NationalMoonshineWeek/sections/Recipes/Recipes";
import { Footer } from "../components/NationalMoonshineWeek/sections/Footer/Footer";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  min-height: 100vh;
  background-color: #171717;
`;

export const HomeWrapper = styled.div`
  position: relative;
  width: 100%;
  background-image: linear-gradient(
      178deg,
      rgba(0, 0, 0, 0) 1.36%,
      #171717 98.99%
    ),
    url(${bgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;

const NationalMoonshineWeekPage = ({ location }) => {
  const { hash } = location;
  const initialActiveSection = hash ? hash.substring(1) : "home";
  const [activeSection, setActiveSection] = useState(initialActiveSection);
  const [isSticky, setIsSticky] = useState(false);

  const handleSticky = () => {
    window.scrollY >= 48 ? setIsSticky(true) : setIsSticky(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleSticky);
    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfaaKwcAAAAAKA_cozC1lBk7DvgVucS97WeS-MY">
      <GlobalStyle />
      <Root id="home">
        {/* <Counter /> */}
        <HomeWrapper>
          <Navigation
            activeSection={activeSection}
            className={isSticky && "--sticky"}
          />
          <Home onIntersected={() => setActiveSection("home")} />
        </HomeWrapper>
        <Prizes id="prizes" onIntersected={() => setActiveSection("prizes")} />
        <Recipes
          id="recipes"
          onIntersected={() => setActiveSection("recipes")}
        />
        <Footer />
      </Root>
    </GoogleReCaptchaProvider>
  );
};

export default NationalMoonshineWeekPage;
