import grand1Src from "../../assets/nmw-grand-prize.jpg";

import first1Src from "../../assets/nmw-first-prize.jpg";

import second1Src from "../../assets/nmw-second-prize-1.jpg";
import second2Src from "../../assets/nmw-second-prize-2.jpg";

export const grandPrize = [
  {
    id: 1,
    image: grand1Src,
  },
];

export const firstPrize = [
  {
    id: 1,
    image: first1Src,
  },
];

export const secondPrize = [
  {
    id: 1,
    image: second1Src,
  },
  {
    id: 2,
    image: second2Src,
  },
];

export const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};
