import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-7, 56px);
  width: 100%;
  background-color: #161515;

  @media (max-width: 1023px) {
    gap: var(--space-5, 40px);
  }

  @media (min-width: 1024px) {
    gap: var(--space-5, 40px);

    padding: var(--space-8, 64px) var(--space-10, 80px) var(--space-5, 40px)
      var(--space-10, 80px);
  }

  @media (min-width: 1440px) {
    gap: var(--space-7, 56px);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    max-width: 864px;
  }

  @media (min-width: 1440px) {
    max-width: 1280px;
  }
`;

export const Logo = styled.a`
  width: 96px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    row-gap: 56px;
    width: 100%;
    text-align: center;
  }

  @media (min-width: 1024px) {
    width: 80%;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 300px;
  }
`;

export const Title = styled.div`
  color: var(--color-base-white, #fff);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const NavLink = styled.a`
  display: inline-block;
  padding: 0;
  color: var(--color-text-secondary, #c3c3c3);
  font-family: Gotham;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.22px;
  font-family: inherit;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-1-5, 12px);

  @media (max-width: 1023px) {
    justify-content: center;
  }
`;

export const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-md, 24px);
  height: var(--size-md, 24px);
  color: #fff;
`;

export const EmailCTAContainer = styled.div`
  @media (min-width: 1440px) {
    width: 511px;
  }
`;

export const ToTopButton = styled.a`
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  background-color: #171717;
  border-radius: 200px;
`;

export const LegalContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    max-width: 864px;
  }

  @media (min-width: 1440px) {
    max-width: 1280px;
  }
`;

export const Legal = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  max-width: 1280px;
  color: var(--color-text-secondary, #c3c3c3);
  font-size: 11px;
  font-weight: 325;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.22px;

  @media (max-width: 1023px) {
    text-align: center;
    margin-bottom: 40px;
  }

  a {
    color: inherit;
    text-decoration-line: underline;
  }
  p {
    margin: 0;
  }
`;
