import styled, { keyframes } from "styled-components";

import polaroidMidSrc from "../../assets/polaroid-1024.png";
import polaroidMaxSrc from "../../assets/polaroid-1440.png";

export const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    row-gap: 28px;
  }

  @media (min-width: 1024px) {
    row-gap: 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: var(--space-2, 16px) var(--space-3, 24px) var(--space-5, 40px)
      var(--space-3, 24px);
  }

  @media (min-width: 1024px) {
    padding: var(--space-2, 16px) var(--space-6, 48px) var(--space-5, 40px)
      var(--space-6, 48px);
  }

  @media (min-width: 1440px) {
    padding: var(--space-2, 16px) var(--space-15, 120px) var(--space-5, 40px)
      var(--space-15, 120px);
  }
`;

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    column-gap: 24px;
  }

  @media (min-width: 1440px) {
    column-gap: 80px;
  }
`;

export const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    max-width: 508px;
  }

  @media (min-width: 1024px) {
    max-width: 530px;
  }

  @media (min-width: 1440px) {
    max-width: 605px;
  }
`;

export const HeadlineImage = styled.img`
  width: 100%;
  max-width: 508px;
`;

export const HeadlineText = styled.div`
  color: #fff;
  font-family: "YWFT Ultramagnetic";
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 40px;
    margin-bottom: 32px;
    font-size: 22px;
    line-height: 30px;
  }

  @media (min-width: 1024px) {
    margin-top: 56px;
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 34px;
  }

  span {
    color: #caa042;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  @media (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 32px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 48px;
  padding: 1px 24px 0;
  color: #fff;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  border: 1px solid #a0a0a0;
  border-radius: 24px;
  transition: box-shadow 150ms ease;

  @media (max-width: 1439px) {
    text-align: center;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  &::placeholder {
    color: #c3c3c3;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
  }

  &.--error {
    border-color: red;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
  }
`;

export const Submit = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  height: 48px;
  color: #fff;
  background-color: #caa042;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  border-radius: 24px;
  transition: box-shadow 150ms ease;

  &:disabled {
    background-color: #d6c8ac;
    cursor: default;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 189px;
  }

  span {
    margin-top: 1px;
  }
`;

export const PolaroidContainer = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 40%;
    padding: 32px 0;
    background-image: url(${polaroidMidSrc});
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (min-width: 1440px) {
    width: 100%;
    max-width: 515px;
    background-image: url(${polaroidMaxSrc});
  }
`;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Preloader = styled.div`
  width: 19px;
  height: 19px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  padding: 8px 12px;
  background-color: #fff;
  font-size: 12px;
  border-radius: 10px;
`;

export const Legal = styled.div`
  color: var(--color-text-primary, #fff);
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: 18px; /* 163.636% */
  letter-spacing: 0.22px;

  button {
    margin: 0;
    padding: 0;
    color: var(--color-text-highlight, #caa042);
    font: inherit;
  }
`;
