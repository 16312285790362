import styled from "styled-components";

const SliderStyled = styled.div`
  width: 100%;
  height: 100%;

  .slick-list,
  .slick-slider,
  .slick-track {
    display: block;
    position: relative;
    height: 100%;
  }

  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slider {
    user-select: none;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: 0;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    top: 0;
    left: 0;
  }

  .slick-track::after,
  .slick-track::before {
    display: table;
    content: "";
  }

  .slick-track::after {
    clear: both;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir="rtl"] .slick-slide {
    float: right;
  }

  .slick-slide > div {
    height: 100%;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  /* ----------------- Dots ----------------- */

  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    margin: 100px 0 0;
    padding: 0;
    list-style: none;
  }

  .slick-dots li {
    position: relative;
    width: 11px;
    height: 11px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 11px;
    }
  }

  .slick-dots li button {
    display: block;
    width: 100%;
    height: 100%;
    color: transparent;
    font-size: 0;
    line-height: 0;
    outline: 0;
  }

  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }

  .slick-dots li button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }

  .slick-dots li button:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #b2b2b2;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.41);
    border-radius: 20px;
    opacity: 0.18;
  }

  .slick-dots li.slick-active button:before {
    background-color: #f3f3f3;
    opacity: 1;
  }

  /* ----------------- Arrows ----------------- */

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-next,
  .slick-prev {
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    z-index: 1;

    @media (max-width: 1023px) {
      bottom: 30px;
    }

    @media (min-width: 1024px) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .slick-next.slick-disabled,
  .slick-prev.slick-disabled {
    cursor: initial;
  }

  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    outline: 0;
  }

  .slick-next:focus::before,
  .slick-next:hover::before,
  .slick-prev:focus::before,
  .slick-prev:hover::before {
    opacity: 1;
  }

  .slick-next.slick-disabled::before,
  .slick-prev.slick-disabled::before {
    opacity: 0.5;
  }

  .slick-next::before,
  .slick-prev::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 1023px) {
      background-size: 20px;
    }

    @media (min-width: 1024px) {
      background-size: 32px;
    }
  }

  .slick-prev {
    @media (max-width: 1023px) {
      left: 0;
    }

    @media (min-width: 1024px) {
      left: -40px;
    }
  }

  .slick-prev::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23e5e2db' d='m12.367 16.009 12.109 12.1c.896.89.896 2.34 0 3.23a2.306 2.306 0 0 1-3.239 0L7.518 17.629a2.283 2.283 0 0 1-.067-3.154L21.228.67c1.245-1.247 3.372-.679 3.83 1.022a2.289 2.289 0 0 1-.591 2.211l-12.1 12.106Z' data-name='Icon ionic-ios-arrow-forward'/%3E%3C/svg%3E");
  }

  .slick-next {
    @media (max-width: 1023px) {
      right: 0;
    }

    @media (min-width: 1024px) {
      right: -40px;
    }
  }

  .slick-next::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23e5e2db' d='M19.633 15.991 7.524 3.891a2.277 2.277 0 0 1 0-3.23 2.306 2.306 0 0 1 3.239 0l13.719 13.71c.862.864.891 2.254.067 3.154L10.772 31.33c-1.245 1.247-3.372.679-3.83-1.022a2.289 2.289 0 0 1 .591-2.211l12.1-12.106Z' data-name='Icon ionic-ios-arrow-forward'/%3E%3C/svg%3E");
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
`;

export default SliderStyled;
