import React from "react";

const OfficialUs = () => (
  <div>
    <h2>
      <strong>Ole Smoky National Moonshine Week Sweepstakes</strong>
    </h2>
    <p>
      <strong>Official Sweepstakes Rules</strong>
    </p>
    <br />

    <p>
      NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE
      YOUR CHANCES OF WINNING. ALL FEDERAL, STATE, LOCAL, AND MUNICIPAL LAWS AND
      REGULATIONS APPLY. VOID WHERE PROHIBITED.
    </p>
    <p>
      OFFERED ONLY TO LEGAL RESIDENTS OF THE FIFTY UNITED STATES AGED 21 YEARS
      OR OLDER. PARTICIPATION CONSTITUTES ENTRANT’S FULL AND UNCONDITIONAL
      AGREEMENT TO AND ACCEPTANCE OF THESE OFFICIAL SWEEPSTAKES RULES, WHICH ARE
      FINAL AND BINDING. WINNING A PRIZE IS CONTINGENT UPON COMPLYING WITH THE
      OFFICIAL SWEEPSTAKES RULES AND FULFILLING ALL CONDITIONS TO WIN, WHICH ARE
      FULLY DESCRIBED HEREIN.
      <br />
      POTENTIAL WINNERS WILL BE REQUIRED TO RESPOND TO WINNER NOTIFICATION AND
      OTHER COMMUNICATIONS FROM SPONSOR (DEFINED BELOW).
    </p>

    <ol>
      <li>
        <p>
          <strong>Eligibility:</strong> Participation open to legal U.S.
          residents who reside in the fifty (50) United States and the District
          of Columbia “(DC”) who are 21 years of age or older at the time of
          entry, except that employees of Ole Smoky Distillery, LLC, its
          subsidiaries, affiliates, divisions, advertising and promotion
          agencies, individuals engaged in the development, production or
          distribution of materials for this Sweepstakes, individuals employed
          by a state alcohol commission, individuals employed by a licensed
          alcoholic beverage retailer, distributor, or wholesaler, and each of
          their immediate family members and persons living in the same
          households of each (whether related or not) are not eligible to
          participate. Void outside the fifty (50) United Sates and DC and where
          prohibited by law.
        </p>
      </li>

      <li>
        <p>
          <strong>Promotion Description:</strong> “The Ole Smoky National
          Moonshine Week Sweepstakes” (the “Sweepstakes”) sponsored by Ole Smoky
          Distillery (the “Sponsor”) begins at 12:00:00 AM (ET) on April 1, 2024
          and ends at 11:59:59 PM (ET) on June 30, 2024 (the “Sweepstakes
          Period”). Eligible entrants may access the promotional website at{" "}
          <a href="https://nationalmoonshineweek.olesmoky.com/">
            https://nationalmoonshineweek.olesmoky.com/
          </a>{" "}
          for a chance to win one of the one hundred four (104) available
          prizes.
        </p>
      </li>

      <li>
        <p>
          <strong>Limit on Entries & How to Enter:</strong> Limit one (1)
          Sweepstakes entry per day over course of the Sweepstakes Period per
          person, email address or household address. Entries over this limit
          are void. All entries become the Sponsor’s property. During the
          Sweepstakes Period, eligible entrants may register for the Sweepstakes
          drawing via “The Ole Smoky National Moonshine Week Sweepstakes”
          promotional website (the “Website”) which can be accessed by eligible
          participants at nationalmoonshineweek.olesmoky.com. At the Website,
          the eligible participant must then complete the registration
          information as directed to enter the Sweepstakes. Entry may only be
          made by the Sweepstakes entrant. No scripts, macros or other means of
          mass / group / database entries will be permitted.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Random Sweepstakes Drawing / Prizes / Approximate Retail Values
            (“ARV”) / Odds:
          </strong>{" "}
          On or about July 6, 2024, a random drawing will be conducted by an
          independent sweepstakes administrator from among all timely, valid,
          eligible entries received over the course of the Sweepstakes Period to
          award the one hundred four (104) available prizes. All decisions of
          Sponsor and/or administrator shall be final and binding in all
          respects. Odds of winning a prize depends upon the total number of
          eligible entries received over the course of the Sweepstakes Period.
          <strong>
            One (1) Grand Prize: VIP Trip to Gatlinburg, TN for Four:
          </strong>{" "}
          The Grand Prize consists exclusively of: roundtrip (“R/T”) coach air
          transportation for Grand Prize winner and up to three (3) travel
          companions between major gateway airport nearest winner’s residence
          and a destination airport in Knoxville TN; three (3) consecutive night
          hotel room accommodations (two standard rooms, double occupancy; room
          and tax only); R/T ground transportation between airport and hotel in
          Gatlinburg, TN; private tour for four (4) of Ole Smoky Distillery
          facility including a $200 store credit; four (4) Ole Smoky gift
          baskets containing branded merchandise and apparel items; Attraction
          Pass for four (4) to a Gatlinburg, TN area attraction to be selected
          by the Sponsor, and which is dependent upon the travel dates selected;
          one (1) $500 gift card which the winner may elect to use for meals
          while in Gatlinburg, TN. Approximate retail value of the Grand Prize
          is $7,100.
        </p>
        <p>
          The Grand Prize winner will receive a 1099 tax form for the
          approximate retail value (“ARV”) of the prize. Each of the Grand Prize
          winner’s travel companions must be twenty-one (21) years of age or
          older. The Grand Prize winner and his or her travel companions must
          travel together on the same itinerary and possess all required travel
          documents prior to awarding of prize. Air transportation tickets are
          non-refundable/non-transferable and are not valid for upgrades and/or
          frequent flyer miles. Sponsor reserves the right to structure the
          travel route and select hotel and event tickets in its sole
          discretion. ARV for the Grand Prize may vary depending upon points of
          departure and fare fluctuations. If the closest airport to the Grand
          Prize winner’s home is in close enough proximity to Nashville TN so
          that, as Sponsor may determine in its sole discretion, a flight is
          unnecessary, such prize winner will receive alternate ground
          transportation from the Sponsor with no further obligation to the
          winner. Grand Prize winner will not receive cash or any other form of
          compensation if actual travel costs are less than the Grand Prize ARV
          in these Official Rules. In the event any scheduled Grand Prize event
          and/or any other component of the prize is cancelled, delayed, or
          postponed for any reason outside the control of the Sponsor, Sponsor
          will have no further obligation, other than to award the remainder of
          the prize. In the event Grand Prize winner and/or his or her travel
          companions engage in behavior that (as determined by Sponsor in its
          sole and absolute discretion) is obnoxious or threatening, illegal or
          that is intended to annoy, abuse, threaten or harass any other person,
          Sponsor reserves the right to terminate the trip or other applicable
          experience early, in whole or in part, and send Grand Prize winner
          and/or his or her travel companions home with no further compensation.
        </p>
        <p>
          The Grand Prize winner must provide Sponsor a minimum of 30 days
          advance notice of desired departure, all travel must be completed by
          December 31, 2025 and travel must be on dates approved by the Sponsor.
          Dates of departure and return are subject to change at Sponsor’s sole
          discretion. If the Grand Prize winner is unable to participate in the
          Grand Prize trip during dates approved by Sponsor, the Grand Prize
          will be forfeited in its entirety. Odds of winning the Grand Prize
          depend on the total number of eligible entries received over the
          course of the Sweepstakes Period.
        </p>
        <p>
          <strong>
            Three (3) First Prizes: Ole Smoky Distillery Branded Hammock.
          </strong>{" "}
          Each First Prize consists exclusively of one (1) Ole Smoky Distillery
          Hammock. Approximate retail value of each First Prize is $300.
        </p>
        <p>
          <strong>One Hundred (100) Second Prizes: Ole Smoky T-Shirt.</strong>{" "}
          Each Second Prize consists of one (1) Ole Smoky t-shirt. Size
          determined by Sponsor. Approximate retail value of each Second Prize
          is $12. ARV of all available prizes is $9,200. All prize winners must
          twenty-one years of age or older. Sponsor reserves the right to verify
          age eligibility prior to award of any prize.
        </p>
      </li>
      <li>
        <p>
          <strong>Potential Winner Notification:</strong> Potential prize
          winners will be notified within twenty-four (24) hours of the random
          daring via email by a representative of the Sponsor. In response to
          the prize notification email, the potential winner must send a reply
          email with his/her/their name, complete mailing address and email
          address in order to claim the prize. Potential prize winners may be
          required to sign an Affidavit of Eligibility/Release of
          Liability/Publicity and Advertising Consent and Release (“Prize Claim
          Documents”) (except where prohibited by law) and such other documents
          reasonably required by Sponsor as part of the prize claim process. All
          such documents must be signed and returned within five (5) days of
          notification or the potential prize winner will be deemed disqualified
          and the prize will be forfeited and an alternate winner may be
          selected as provided for in these Official Rules. Any potential prize
          winner is subject to verification before any prize will be awarded. In
          the event that any potential prize winner is disqualified for any
          reason, an alternate winner will be selected in a random drawing from
          all eligible entries received during the Sweepstakes Period. If,
          despite reasonable efforts, a potential winner does not respond within
          three (3) days of the first notification attempt and return his/her
          executed Prize Claim Documents within five (5) days of the first
          notification attempt, or if the prize or prize notification is
          returned as unclaimed or undeliverable to such potential winner, such
          potential winner will forfeit his or her prize and an alternate winner
          may be selected. Sponsor, in its sole discretion, will attempt to
          contact up to three (3) potential winners of any prize in accordance
          with the above procedure, after which the prize in question may go
          unawarded if it remains unclaimed. Return of any prize notification as
          undeliverable for any reason will result in disqualification.
        </p>
      </li>
      <li>
        <p>
          <strong>Conditions of Participation & Certain Restrictions:</strong>{" "}
          The participant is solely responsible for entering the Sweepstakes
          between April 1, 2024 and June 30, 2024, as well as properly claiming
          a prize within five (5) days of prize notification if selected as a
          prize winner. Prize winners must agree to certain terms and conditions
          related to eligibility, release of liability, and Sponsor’s use of
          winner's name and/or likeness for publicity, advertising and
          promotional purposes, without compensation (unless prohibited by law)
          as part of the prize claim process. Noncompliance may result in
          disqualification and forfeiture of prize. Once a prize has been
          delivered to the winner’s residence via delivery-confirmed mail or
          courier, the prize is considered “awarded.” Taxes on any prize are the
          sole responsibility of the winner. No cash equivalents, prize
          substitutions or transfer of prize permitted except that Sponsor
          reserves the right to substitute a prize of equal or greater value in
          the event that an offered prize is unavailable. Grand Prizes are
          limited to the prize elements described in paragraph No. 4 of these
          Official Rules and do not include any other fees, taxes or prize
          elements. Acceptance of prize constitutes permission to the Sponsor to
          use winner's name and/or likeness for purposes of advertising and
          promotion related to the Sweepstakes without additional compensation,
          unless prohibited by law. All entries become the property of Sponsor
          and will not be acknowledged or returned. By entering, entrants
          acknowledge compliance with these Official Rules, including all
          eligibility requirements. By participating, entrants agree to release
          Sponsor and its affiliates, subsidiaries, divisions, wholesalers,
          retailers or distributors or advertising and promotion agencies and
          each of their respective officers, directors, employees, agents or
          representatives (collectively, “Released Parties”) from and against
          any and all claims, demands, losses and liabilities of any nature
          whatsoever, which entrant may now or hereafter be entitled to assert,
          including but not limited to, any death, injury, loss of enjoyment,
          damage to computer equipment, or other harm or loss of any nature
          whatsoever caused or contributed to by participation in the
          Sweepstakes, any of the prizes awarded in the Sweepstakes and/or in
          connection with the awarding, receipt and use or misuse of prize,
          and/or claims based on publicity rights, defamation or invasion of
          privacy. Winners acknowledge that neither Sponsor nor its agents have
          made nor are in any manner responsible or liable for any warranty,
          representation or guarantee, express or implied, in fact or in law,
          relative to any prize, including, but not limited to, its quality,
          mechanical condition or fitness for a particular purpose. Any and all
          warranties and/or guarantees on a prize, if any, are subject to the
          manufacturers’ terms therefore and winners agree to look solely to
          such manufacturers for any such warranty and/or guarantee. By
          participating in this Sweepstakes, entrants agree to be bound by the
          Official Rules and the decisions of the Sponsor, which are final and
          binding in all respects. Sponsor not responsible for any typographical
          or other error in the printing of the offer or in administration of
          the Sweepstakes.
        </p>
      </li>
      <li>
        <p>
          <strong>Disclaimer & Release of Liability.</strong> THE SPONSOR AND
          RELEASED PARTIES ARE NOT RESPONSIBLE FOR ANY INCORRECT OR INACCURATE
          ENTRY INFORMATION, HUMAN ERROR, TECHNICAL MALFUNCTION, FAILURES,
          OMISSION, INTERRUPTION, DELETION, OR DEFECT OF ANY TELEPHONE NETWORK,
          COMPUTER ONLINE SYSTEMS, COMPUTER EQUIPMENT, SERVERS, ACCESS
          PROVIDERS, OR SOFTWARE, INCLUDING ANY INJURY OR DAMAGE TO PARTICIPANTS
          OR ANY OTHER PERSONS' COMPUTER RELATING TO OR RESULTING FROM
          PARTICIPATION IN THIS COMPETITION; INABILITY TO ACCESS THE ENTRY
          WEBSITE, APPLICATION OR ANY PAGES THEREOF; THEFT; TAMPERING;
          DESTRUCTION; OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF ENTRIES;
          ENTRY SUBMISSIONS THAT ARE PROCESSED LATE OR INCORRECTLY OR ARE
          INCOMPLETE, GARBLED, OR LOST DUE TO COMPUTER OR ELECTRONIC MALFUNCTION
          OR TRAFFIC CONGESTION ON THE INTERNET OR ANY WEBSITE OR FOR ANY OTHER
          REASON. PROOF OF ENTERING INFORMATION ON THE WEBSITE OR THE
          APPLICATION IS NOT CONSIDERED PROOF OF DELIVERY OR RECEIPT. ALL DATES
          SET FORTH IN THESE TERMS AND CONDITIONS ARE APPROXIMATE. THE SPONSOR
          RESERVES THE RIGHT TO EXTEND OR SHORTEN ANY DEADLINES. FALSE AND/OR
          DECEPTIVE ENTRIES OR ACCOUNTS SHALL RENDER ENTRY INELIGIBLE.
        </p>
        <p>
          ALL DECISIONS OF THE SPONSOR SHALL BE FINAL AND CONCLUSIVE ON ALL
          MATTERS RELATING TO THE COMPETITION.
        </p>
        <p>
          ALL ENTRANTS AND WINNERS RELEASE, DEFEND, INDEMNIFY, AND HOLD HARMLESS
          THE SPONSOR AND RELEASED PARTIES FROM AND AGAINST ANY AND ALL
          LIABILITY WITH RESPECT TO, OR IN ANY WAY ARISING FROM, IN WHOLE OR IN
          PART, DIRECTLY OR INDIRECTLY, THIS SWEEPSTAKES, PUBLICATION OR USE OF
          THE SUBMITTED MATERIALS OR ADDITIONAL MATERIALS, ENTRANTS' NAMES AND
          LIKENESSES, AND/OR ACCEPTANCE, USE, MISUSE, LOSS, OR MISDIRECTION OF
          PRIZES, INCLUDING LIABILITY FOR PERSONAL INJURY, DEATH, DAMAGES, OR
          MONETARY LOSS.
        </p>
        <p>
          IN NO EVENT SHALL THE SPONSOR OR PROMOTION ENTITIES BE LIABLE OR
          OBLIGATED TO AN ENTRANT, WINNER, OR ANY THIRD PARTY IN ANY MANNER FOR
          ANY SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE, OR
          INDIRECT DAMAGES OF ANY KIND REGARDLESS OF THE FORM OF ACTION, WHETHER
          IN CONTRACT, TORT, NEGLIGENCE, STRICT PRODUCT LIABILITY, OR OTHERWISE,
          EVEN IF INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGES IN ADVANCE.
          THIS SECTION SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
          LAW AND NOTWITHSTANDING THE FAILURE OF ANY LIMITED REMEDY.
        </p>
      </li>
      <li>
        <p>
          <strong>Governing Law & Forum.</strong> All federal, state and local
          laws and regulations are applicable. By entering, participants agree
          that all issues and questions concerning the construction, validity,
          interpretation and enforceability of the Sweepstakes, these Official
          Rules, participants’ rights and obligations, or the rights and
          obligations of the Sponsor in connection with the Sweepstakes shall be
          governed by, and construed in accordance with, the laws of the State
          of Tennessee, without giving effect to any choice of law or conflict
          of law rules, which would cause the application of the laws of any
          jurisdiction other than the State of Tennessee, irrespective of the
          fact that any one of the parties is now or may become a resident of a
          different state. By entering, participants consent to the jurisdiction
          and venue of the federal, state and local courts located in Nashville,
          Tennessee and hereby waive any objection to such jurisdiction and
          venue. Any claim or cause of action arising out of or related to this
          Sweepstakes or these Official Rules must be filed within one (1) year
          after such claim or cause of action arose regardless of any law to the
          contrary. In the event any such claim or cause of action is not filed
          within such one (1) year period, such claim or cause of action shall
          be forever barred.
        </p>
      </li>
      <li>
        <p>
          <strong>Severability.</strong> If any provision of these Official
          Rules shall be held invalid, illegal or unenforceable, such provision
          shall be enforced to the maximum extent permitted by law and the
          Sponsor's fundamental intentions hereunder, and the remaining
          provisions shall not be affected or impaired, provided, however, that
          in such cases the parties oblige themselves to use their best efforts
          to achieve the purpose of the invalid provision by a new legally valid
          stipulation.
        </p>
      </li>
      <li>
        <p>
          <strong>Entire Agreement.</strong> The Official Rules constitute the
          entire agreement between Sponsor and any and all Sweepstakes
          participants with respect to all aspects of the Sweepstakes. The
          Official Rules supersede and cancel any and all prior and
          contemporaneous negotiations, agreements, and understandings (oral and
          written) with respect to the Sweepstakes.
        </p>
      </li>
      <li>
        <p>
          <strong>Confidentiality:</strong> For information about how the
          Sponsor uses your personal information, please see its privacy
          statement, located at{" "}
          <a
            href="https://olesmoky.com/pages/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy - Ole Smoky Distillery
          </a>
        </p>
      </li>
      <li>
        <p>
          <strong>Winner’s List:</strong> To receive name and city / state of
          residence of winners of prizes valued at $25 or more, mail a stamped,
          self-addressed envelope accompanied with a signed, hand-printed
          request to “Ole Smoky Sweepstakes Winner’s List”, PO Box 11508,
          Bozeman, MT 59719-1508. All requests must be received by November 30,
          2024.
        </p>
      </li>
      <li>
        <p>
          <strong>Sponsor:</strong> Ole Smoky Distillery, LLC. 101 Bruce Street,
          Sevierville, TN 37862.
        </p>
      </li>
    </ol>
  </div>
);

export default OfficialUs;
