import styled from "styled-components";

export const Root = styled.form`
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: transparent;
  background-image: none;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.6px;
  border: 1px solid #818386;
  border-radius: 25px;
  outline: none;
  box-shadow: none;
  appearance: none;

  &::placeholder {
    color: #818386;
  }

  &:hover,
  &:focus {
    border: 1px solid #fff;
  }

  @media (max-width: 1023px) {
    padding: 0 20px;
    text-align: center;
  }

  @media (min-width: 1024px) {
    padding: 0 32px;
  }
`;

export const CtaError = styled.span`
  display: block;
  position: absolute;
  bottom: -19px;
  left: 0;
  width: 100%;
  color: #f44336;
  font-size: 12px;
  text-align: center;
`;

export const SubmitIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M9.787 8.006 3.83 1.965a1.15 1.15 0 0 1 0-1.612 1.122 1.122 0 0 1 1.594 0l6.749 6.845c.423.431.437 1.124.032 1.574l-6.78 6.892c-.628.606-1.667.296-1.871-.558-.09-.376.014-.773.277-1.054l5.956-6.046Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 350ms ease;

  @media (max-width: 1023px) {
    display: none;
    width: 16px;
    height: 16px;
  }

  @media (min-width: 1024px) {
    width: 13px;
    height: 13px;
    margin-left: 6px;
  }
`;

export const Submit = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--size-xl, 48px);
  padding: var(--space-0, 0px) var(--space-3, 24px);
  background-color: #c49f3f;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 1px solid #c49f3f;
  border-radius: 35px;
  cursor: pointer;

  span {
    padding-top: 3px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 12px;

    span {
      margin: 0 auto;
    }
  }

  @media (min-width: 1024px) {
    margin-left: 10px;
  }

  &:disabled {
    opacity: 0.75;
    cursor: default;
  }

  &:hover ${SubmitIcon} {
    transform: translateX(5px);
  }
`;
