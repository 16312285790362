import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  outline: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 7000;

  @media (max-width: 1023px) {
    padding: 0 25px;
  }
`;

export const Dialog = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  pointer-events: none;

  @media (max-width: 1023px) {
    height: calc(100% - 96px);
    overflow-y: auto;
  }

  @media (min-width: 1024px) {
    width: calc(100% - 120px);
    height: calc(100% - 96px);
    overflow-y: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-height: 100%;
  outline: 0;
  pointer-events: all;

  @media (min-width: 1024px) {
    max-width: 1685px;
  }
`;

export const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 0;
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  border: none;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 1023px) {
    top: 10px;
    right: 10px;
  }

  @media (min-width: 1024px) {
    top: 32px;
    right: 32px;
  }
`;

export const Body = styled.div`
  .slick-prev {
    left: 0 !important;
  }
  .slick-next {
    right: 0 !important;
  }

  @media (max-width: 1023px) {
    .slick-prev {
      top: 135px;
    }
    .slick-next {
      top: 135px;
    }
  }

  @media (min-width: 1024px) {
    .slick-prev,
    .slick-next {
      top: 235px;
    }
  }

  @media (min-width: 1600px) {
    max-width: 1431px;
    margin: 0 auto;
  }
`;

export const Slide = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 1023px) {
    max-width: 440px;
  }

  @media (max-width: 479px) {
    margin-bottom: -140px;
  }

  @media (min-width: 480px) {
    margin-bottom: -180px;
  }

  @media (min-width: 1024px) {
    max-width: 540px;
    margin-bottom: -105px;
  }
`;

export const RecipeContainer = styled.div`
  width: 100%;
  background-color: #202020;
  border-radius: 2px;
  /* box-shadow: 25px -45px 99px 0 rgba(0, 0, 0, 0.65); */

  @media (max-width: 1023px) {
    padding: 35px 20px 26px;
  }

  @media (min-width: 1024px) {
    max-width: 1431px;
    padding: 48px 73px 42px 65px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
`;

export const Subtitle = styled.div`
  color: var(--color-text-secondary, #c3c3c3);
  font-size: 14px;
  font-weight: 350;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.7px;
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-family: "YWFT Ultramagnetic";
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

export const RecipeBody = styled.div`
  display: flex;
  background-image: linear-gradient(
    to right,
    #818386 60%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 20px 1px;
  background-repeat: repeat-x;
  background-position-y: 0;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    margin-top: 28px;
    padding-top: 28px;
    text-align: center;
  }

  @media (min-width: 1024px) {
    justify-content: space-around;
    margin-top: 45px;
    padding-top: 48px;
  }
`;

export const RecipeBlock = styled.div`
  @media (max-width: 1023px) {
    & + & {
      margin-top: 20px;
    }
  }
`;

export const RecipeTitle = styled.div`
  color: #c49f3f;
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 1.94;
    letter-spacing: 1.44px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 1.75;
    letter-spacing: 1.6px;
  }
`;

export const RecipeList = styled.ul`
  list-style: none;

  @media (max-width: 1023px) {
    margin-top: 6px;
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
  }
`;

export const RecipeListItem = styled.li`
  @media (max-width: 1023px) {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
  }

  @media (min-width: 1024px) {
    color: #c3c3c3;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.32px;
  }
`;

export const DirIndex = styled.span`
  color: #c49f3f;
  font-weight: 700;

  @media (max-width: 1023px) {
    display: block;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 2.19;
    letter-spacing: 1.28px;
  }
`;
