"use client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Root,
  Container,
  TextContainer,
  HeadlineImage,
  HeadlineText,
  Form,
  Input,
  Submit,
  PolaroidContainer,
  // Preloader,
  // ErrorMessage,
  Content,
  Legal,
} from "./Home.style";

import useIntersection from "../../../../hooks/useIntersection";
import ModalEntry from "../../components/ModalEntry/ModalEntry";
import ModalRules from "../../components/ModalRules/ModalRules";
import headlineSrc from "../../assets/headline.svg";
import { useRef } from "react";
import { useEffect } from "react";

export const Home = ({ id, onIntersected }) => {
  const ref = useRef(null);
  const isIntersected = useIntersection(ref);

  useEffect(() => {
    if (isIntersected) {
      onIntersected();
    }
  }, [isIntersected, onIntersected]);

  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const [formMessage, setFormMessage] = useState();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [entryShow, setEntryShow] = useState(false);
  const [rulesShow, setRulesShow] = useState(false);
  const [backToEntry, setBackToEntry] = useState(false);

  const {
    handleSubmit,
    register,
    // reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  // const onSubmit = async data => {
  //   if (!executeRecaptcha) {
  //     setFormMessage({
  //       type: "error",
  //       message: "Invalid reCAPTCHA. Please refresh page and try again.",
  //     });
  //     return;
  //   }
  //   setIsSubmitting(true);
  //   const captcha = await executeRecaptcha("entry");
  //   setFormMessage(null);
  //   fetch("https://api.olesmoky.com/nmw-2024-comingsoon", {
  //     method: "POST",
  //     headers: { "Content-type": "application/json" },
  //     mode: "cors",
  //     body: JSON.stringify({ email: data.email, captcha }),
  //   })
  //     .then(response => {
  //       if (response.ok) return response.json();
  //       return Promise.reject(response);
  //     })
  //     .then(() => {
  //       setFormMessage({
  //         type: "success",
  //         message: "Success! You'll be notified when the sweepstakes starts.",
  //       });
  //       reset();
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       setFormMessage({
  //         type: "error",
  //         message: "An error occurred.  Please try again.",
  //       });
  //     })
  //     .finally(() => setIsSubmitting(false));
  // };

  return (
    <Root ref={ref} id={id}>
      <Content>
        <Container>
          <TextContainer>
            <HeadlineImage
              src={headlineSrc}
              alt="C'mon & Celebrate / National Moonshine Week / June 1-7, 2024"
            />
            <HeadlineText>
              Celebrate National Moonshine Week
              <br />
              with a chance to{" "}
              <span>
                win a vip trip to
                <br />
                the Smoky Mountains
              </span>
            </HeadlineText>

            <Form
              onSubmit={handleSubmit(() => {
                setBackToEntry(true);
                setEntryShow(true);
              })}
            >
              {/* {formMessage && (
                <ErrorMessage
                  style={{
                    color: formMessage.type === "error" ? "red" : "green",
                  }}
                >
                  {formMessage.message}
                </ErrorMessage>
              )} */}
              <Input
                {...register("email", {
                  required: "Please enter your email address",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
                    message: "Invalid email address",
                  },
                })}
                name="email"
                type="email"
                autoComplete="off"
                placeholder="your email address"
                className={errors?.email && "--error"}
              />
              <Submit type="submit" disabled={Object.keys(errors).length}>
                {/* {isSubmitting ? (
                  <Preloader />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                  >
                    <path
                      fill="#fff"
                      d="M13.781 18.365a.657.657 0 0 1-.656.657h-5.25a.657.657 0 0 1 0-1.313h5.25a.656.656 0 0 1 .656.656ZM18.2 15.74a1.296 1.296 0 0 1-1.137.656H3.937a1.313 1.313 0 0 1-1.132-1.973c.456-.784 1.132-3.002 1.132-5.902a6.562 6.562 0 1 1 13.125 0c0 2.899.678 5.118 1.133 5.902a1.303 1.303 0 0 1 .003 1.317Zm-1.137-.656c-.634-1.089-1.312-3.605-1.312-6.563a5.25 5.25 0 1 0-10.5 0c0 2.958-.68 5.475-1.313 6.563h13.125Z"
                    />
                  </svg>
                )} */}
                {/* {isSubmitting ? (
                  <span>Submitting...</span>
                ) : ( */}
                <span>Enter To Win</span>
                {/* )} */}
              </Submit>
            </Form>

            <Legal>
              Click to review the sweepstakes official{" "}
              <button type="button" onClick={() => setRulesShow(true)}>
                rules and regs
              </button>
              .
            </Legal>
          </TextContainer>

          <PolaroidContainer />
        </Container>
      </Content>

      {entryShow && (
        <ModalEntry
          initialEmail={getValues("email")}
          onClose={() => {
            setValue("email", "");
            setEntryShow(false);
            setBackToEntry(false);
          }}
          onRulesShow={() => {
            setEntryShow(false);
            setRulesShow(true);
          }}
        />
      )}

      {rulesShow && (
        <ModalRules
          onClose={() => {
            setRulesShow(false);
            if (backToEntry) {
              setEntryShow(true);
            }
          }}
        />
      )}
    </Root>
  );
};
