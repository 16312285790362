import styled, { css, keyframes } from "styled-components";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Subtitle = styled.div`
  color: #bb8b2b;
  font-family: "YWFT Ultramagnetic";
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
`;

export const Title = styled.div`
  margin-top: 12px;

  @media (max-width: 1023px) {
    &.--desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    &.--mobile {
      display: none;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

export const Fields = styled.div`
  display: grid;
  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`;

export const Field = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    margin: 10px 0;
  }

  @media (min-width: 768px) {
    margin: 20px 0;
  }
`;

const inputCss = css`
  width: 100%;
  height: 48px;
  padding: 0 29px;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.6px;
  border: 1px solid rgb(129, 131, 134);
  border-radius: 24px;
  outline: none;

  &.--error {
    border-color: red;
  }

  &:not(.--error):focus {
    border: 1px solid #fff;
  }
`;

export const Input = styled.input`
  ${inputCss}

  &::placeholder {
    color: #818386;
  }
`;

export const SelectContainer = styled.div`
  position: relative;

  &::after {
    position: absolute;
    content: "";
    top: 21px;
    right: 20px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
    pointer-events: none;
  }
`;

export const Select = styled.select`
  ${inputCss}
  appearance: none;
`;

export const Agree = styled.label`
  position: relative;
  padding-left: 26px;
  color: var(--color-text-primary, #fff);
  width: 100%;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: 16px;
  letter-spacing: 0.22px;

  @media (max-width: 767px) {
    margin: 25px 0 0 0;
  }

  @media (min-width: 768px) {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  button {
    padding: 0;
    color: var(--color-text-highlight, #caa042);
    font: inherit;
  }
`;

export const Checkbox = styled.input`
  position: absolute;
  top: -3px;
  left: -0;
`;

export const SubmitIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M9.787 8.006 3.83 1.965a1.15 1.15 0 0 1 0-1.612 1.122 1.122 0 0 1 1.594 0l6.749 6.845c.423.431.437 1.124.032 1.574l-6.78 6.892c-.628.606-1.667.296-1.871-.558-.09-.376.014-.773.277-1.054l5.956-6.046Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 350ms ease;

  @media (max-width: 1023px) {
    display: none;
    width: 16px;
    height: 16px;
  }

  @media (min-width: 1024px) {
    width: 13px;
    height: 13px;
    margin-left: 6px;
  }
`;

export const Submit = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-right: 8px;
  padding-left: 10px;
  background-color: #c49f3f;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 1px solid #c49f3f;
  border-radius: 35px;
  cursor: pointer;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 12px;

    span {
      margin: 0 auto;
    }
  }

  @media (min-width: 1024px) {
    padding: var(--space-0, 0px) var(--space-3, 24px);
  }

  &:disabled {
    opacity: 0.75;
    cursor: default;
  }

  &:hover ${SubmitIcon} {
    transform: translateX(5px);
  }
`;

export const Preloader = styled.div`
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const SuccessSubtitle = styled.div`
  margin-top: 30px;
  color: #c49f3f;
  font-family: "YWFT Ultramagnetic";
  font-size: 18px;
  line-height: 1.28;
`;

export const SuccessMsg = styled.div`
  margin-top: 16px;
  color: #fff;
  font-family: "YWFT Ultramagnetic";
  font-size: 34px;
  line-height: 1.29;
  text-align: center;
  text-transform: uppercase;

  span {
    color: #818386;
  }
`;

export const SuccessNote = styled.div`
  max-width: 380px;
  margin-top: 28px;
  color: #c3c3c3;
  font-size: 16px;
  line-height: 1.75;
  text-align: center;

  span {
    color: #c49f3f;
    font-weight: 700;
  }
`;
