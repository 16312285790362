import React, { useState } from "react";

import {
  Root,
  Container,
  Logo,
  MenuBtn,
  DesktopNavList,
  NavLink,
} from "./Navigation.style";

import logoSrc from "../../assets/logo.png";

import { MobileMenu } from "../MobileMenu/MobileMenu";
import ModalEntry from "../ModalEntry/ModalEntry";
import ModalRules from "../ModalRules/ModalRules";
//import ModalGetNotified from "../ModalGetNotified/ModalGetNotified";

export const Navigation = ({ activeSection, className }) => {
  const [menuIsShow, setMenuIsShow] = useState(false);
  // const [getNotifiedShow, setGetNotifiedShow] = useState(false);
  const [entryShow, setEntryShow] = useState(false);
  const [rulesShow, setRulesShow] = useState(false);

  return (
    <Root className={className}>
      <Container>
        <Logo src={logoSrc} alt="Ole Smoky Moonshine logo" />

        {!menuIsShow && (
          <MenuBtn onClick={() => setMenuIsShow(!menuIsShow)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48px"
              height="48px"
              viewBox="0 0 48 48"
            >
              <path
                fill="#fff"
                d="M42 24a1.5 1.5 0 0 1-1.5 1.5h-33a1.5 1.5 0 1 1 0-3h33A1.5 1.5 0 0 1 42 24ZM7.5 13.5h33a1.5 1.5 0 1 0 0-3h-33a1.5 1.5 0 1 0 0 3Zm33 21h-33a1.5 1.5 0 1 0 0 3h33a1.5 1.5 0 1 0 0-3Z"
              />
            </svg>
          </MenuBtn>
        )}

        {menuIsShow && (
          <MobileMenu
            activeSection={activeSection}
            onCloseMenu={() => setMenuIsShow(false)}
            onGetNotified={() => {
              setMenuIsShow(false);
              setEntryShow(true);
            }}
          />
        )}

        <DesktopNavList>
          <li>
            <NavLink
              href="/#home"
              className={activeSection === "home" && "--active"}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              href="/#prizes"
              className={activeSection === "prizes" && "--active"}
            >
              Prizes
            </NavLink>
          </li>
          <li>
            <NavLink
              href="/#recipes"
              className={activeSection === "recipes" && "--active"}
            >
              Cocktails
            </NavLink>
          </li>
          <li>
            <NavLink as="button" onClick={() => setEntryShow(true)}>
              Enter To Win
            </NavLink>
          </li>
        </DesktopNavList>
      </Container>

      {entryShow && (
        <ModalEntry
          onClose={() => setEntryShow(false)}
          onRulesShow={() => {
            setEntryShow(false);
            setRulesShow(true);
          }}
        />
      )}

      {rulesShow && (
        <ModalRules
          onClose={() => {
            setRulesShow(false);
            setEntryShow(true);
          }}
        />
      )}
    </Root>
  );
};
