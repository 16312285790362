import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";

import {
  Root,
  Container,
  Slide,
  SlideBody,
  Image,
  Title,
  Action,
  MentionContainer,
  MentionItem,
} from "./Recipes.style";

import useIntersection from "../../../../hooks/useIntersection";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import SliderStyled from "../../../SliderStyled/SliderStyled";

import RecipesModal from "./RecipesModal";
import ModalRules from "../../../ModalRules/ModalRules";

import { slides, sliderSettings } from "./config";

export const Recipes = ({ id, onIntersected }) => {
  const ref = useRef(null);
  const isIntersected = useIntersection(ref);
  const [slide, setSlide] = useState(false);
  const [rulesShow, setRulesShow] = useState(false);

  useEffect(() => {
    if (isIntersected) {
      onIntersected();
    }
  }, [isIntersected, onIntersected]);

  return (
    <Root ref={ref} id={id}>
      <SectionTitle title="Cocktail recipes" subtitle="Shine Responsibly®" />

      <Container>
        <SliderStyled>
          <Slider {...sliderSettings}>
            {slides.map((slide, idx) => (
              <Slide
                key={slide.id}
                onClick={() => {
                  console.info({ idx: idx });
                  setSlide(idx);
                }}
              >
                <SlideBody>
                  <Title>{slide.label}</Title>
                  <Action>See Recipe</Action>
                </SlideBody>
                <Image
                  src={slide.imageSrc}
                  alt={`Image of the ‘${slide.label}’ cocktail`}
                />
              </Slide>
            ))}
          </Slider>
        </SliderStyled>

        <MentionContainer>
          <MentionItem>
            For questions concerning the National Moonshine Week Sweepstakes,
            please reach out to the Ole Smoky's Customer Service Department:{" "}
            <a href="mailto:customerservice@osdistillery.com" target="_blanc">
              customerservice@osdistillery.com
            </a>
            .
          </MentionItem>
          <MentionItem>
            Click to review the sweepstakes official{" "}
            <button onClick={() => setRulesShow(true)}>rules and regs</button>.
          </MentionItem>
        </MentionContainer>
      </Container>

      {slide !== false && (
        <RecipesModal initialSlide={slide} onClose={() => setSlide(false)} />
      )}

      {rulesShow && (
        <ModalRules
          onClose={() => {
            setRulesShow(false);
          }}
        />
      )}
    </Root>
  );
};
